import React from 'react';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { DEFAULT_LANGUAGE_CODE } from '@bladebinge/web-service-common/src/constants/supported-language-keys';
import { WelcomePage } from '@bladebinge/cms/src/WelcomePage';
import { useUserPreferences } from '../context/user-preferences/user-preferences-context';
import { Layout } from '../components/Layout';

const IndexPage = () => {
    const { listingPagingLimit } = useUserPreferences();

    return (
        <Layout title="Bladebinge">
            <WelcomePage listingPagingLimit={listingPagingLimit} />
        </Layout>
    );
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const { locale = DEFAULT_LANGUAGE_CODE } = context;

    return {
        props: await serverSideTranslations(locale, ['cms', 'common', 'legal'])
    };
};

export default IndexPage;
