import React from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';
import { Theme as MaterialUITheme } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SellIcon from '@mui/icons-material/Sell';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';
import { headingFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { CmsPlacement } from './CmsPlacement';
import { BuySellWinRepeat } from './cms-atoms/BuySellWinRepeat';
import { BROWSING_LINKS } from './constants';
import { HeroCarousel } from './cms-atoms/HeroCarousel';

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module '@emotion/react' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Theme extends MaterialUITheme {}
}

const StyledHelpLink = styled(Link)(({ theme }) => ({
    display: 'block',
    fontSize: '1.4em',
    fontFamily: headingFont.style.fontFamily,
    color: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        textDecoration: 'none'
    },
    marginTop: theme.spacing(1)
}));

const addLimitToLink = (link: string, listingPagingLimit?: number) =>
    `${link}${listingPagingLimit ? `&limit=${listingPagingLimit}` : ''}`;

const CMS_SQUARE_BORDER = {
    borderWidth: '4px',
    borderColor: 'theme.palette.divider',
    borderStyle: 'solid'
};

export const WelcomePage = ({ listingPagingLimit }: { readonly listingPagingLimit?: number }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} justifyContent="space-between">
            <HeroCarousel
                carouselImages={[
                    {
                        src: getCdnUrl('/cms/aug2024/dark_mode_divo_nip_08152024.jpg'),
                        alt: t('cms:headlines.divo_nip'),
                        link: {
                            url: BROWSING_LINKS.bladebinge_shop_dark_mode_collabs,
                            text: t('cms:headlines.divo_nip')
                        }
                    }
                ]}
            />
            <BuySellWinRepeat />
            <Grid item container justifyContent="space-between">
                <Grid container item xs={12} sm={12} md={8}>
                    <CmsPlacement
                        aspectRatio={1}
                        backgroundImageUrl="/logos/bb_shop_grid_ad_425_425.jpg"
                        gridWidth={6}
                        linkTitle={t('cms:browsing_categories.brand_new_knives_from_bladebinge')}
                        primaryLinkUrl={BROWSING_LINKS.bladebinge_shop}
                        seoHeadingTranslationKey="cms:seo_headings.shop_new_gear"
                        textColor="white"
                        sx={{
                            backgroundColor: '#000000',
                            color: '#ffffff',
                            backgroundSize: 'contain',
                            ...CMS_SQUARE_BORDER
                        }}
                    />
                    <CmsPlacement
                        aspectRatio={1}
                        backgroundImageUrl="/cms/jan2024/jan2024_shop_marketplace_425x425.jpg"
                        gridWidth={6}
                        linkTitle={t('cms:browsing_categories.shop_marketplace')}
                        primaryLinkUrl={addLimitToLink(BROWSING_LINKS.new_listings, listingPagingLimit)}
                        seoHeadingTranslationKey="cms:seo_headings.shop_marketplace"
                        subheadingTranslationKey="cms:browsing_categories.shop_marketplace"
                        textColor="primary.contrastText"
                        sx={CMS_SQUARE_BORDER}
                    />
                    <CmsPlacement
                        aspectRatio={1}
                        backgroundImageUrl="/cms/jan2024/jan2024_grails_425x425.jpg"
                        gridWidth={6}
                        linkTitle={t('cms:browsing_categories.grails')}
                        primaryLinkUrl={addLimitToLink(BROWSING_LINKS.grails, listingPagingLimit)}
                        seoHeadingTranslationKey="cms:seo_headings.shop_grails"
                        subheadingTranslationKey="cms:browsing_categories.grails"
                        textColor="primary.contrastText"
                        sx={CMS_SQUARE_BORDER}
                    />
                    <CmsPlacement
                        aspectRatio={1}
                        backgroundImageUrl="/cms/jan2024/jan2024_edc_accessories_425x425.jpg"
                        gridWidth={6}
                        primaryLinkUrl={addLimitToLink(BROWSING_LINKS.accessories, listingPagingLimit)}
                        seoHeadingTranslationKey="cms:seo_headings.shop_edc_accessories"
                        subheadingTranslationKey="cms:browsing_categories.edc_accessories"
                        textColor="primary.contrastText"
                        sx={CMS_SQUARE_BORDER}
                    />
                    <CmsPlacement
                        aspectRatio={1}
                        backgroundImageUrl="/cms/jan2024/jan2024_made_in_usa_425x425.jpg"
                        gridWidth={6}
                        linkTitle={t('cms:browsing_categories.made_in_usa')}
                        primaryLinkUrl={addLimitToLink(BROWSING_LINKS.made_in_usa, listingPagingLimit)}
                        seoHeadingTranslationKey="cms:seo_headings.shop_made_in_usa"
                        subheadingTranslationKey="cms:browsing_categories.made_in_usa"
                        textColor="primary.contrastText"
                        sx={CMS_SQUARE_BORDER}
                    />
                    <CmsPlacement
                        aspectRatio={1}
                        backgroundImageUrl="/cms/jan2024/jan2024_budget_friendly_425x425.jpg"
                        gridWidth={6}
                        linkTitle={t('cms:browsing_categories.budget_friendly')}
                        primaryLinkUrl={addLimitToLink(BROWSING_LINKS.budget_friendly, listingPagingLimit)}
                        seoHeadingTranslationKey="cms:seo_headings.shop_budget_friendly"
                        subheadingTranslationKey="cms:browsing_categories.budget_friendly"
                        textColor="primary.contrastText"
                        sx={CMS_SQUARE_BORDER}
                    />
                </Grid>
                <Grid className="bazilla" container item xs={12} sm={12} md={4} alignContent="flex-start">
                    <Grid item xs={12}>
                        <CmsPlacement
                            gridWidth={12}
                            headingTranslationKey="cms:headlines.why_bladebinge"
                            taglines={[
                                {
                                    taglineTranslationHeadingKey: 'cms:taglines.anyone_can_buy_or_sell_tagline_heading',
                                    taglineTranslationKey: 'cms:taglines.anyone_can_buy_or_sell_tagline',
                                    headingTextColor: 'primary.main'
                                },
                                {
                                    taglineTranslationHeadingKey: 'cms:taglines.secure_payments_tagline_heading',
                                    taglineTranslationKey: 'cms:taglines.secure_payments_tagline',
                                    headingTextColor: 'primary.main'
                                },
                                {
                                    taglineTranslationHeadingKey: 'cms:taglines.trustworthy_tagline_heading',
                                    taglineTranslationKey: 'cms:taglines.trustworthy_tagline',
                                    headingTextColor: 'primary.main'
                                },
                                {
                                    taglineTranslationHeadingKey: 'cms:taglines.real_humans_tagline_heading',
                                    taglineTranslationKey: 'cms:taglines.real_humans_tagline',
                                    headingTextColor: 'primary.main'
                                }
                            ]}
                            useHeadingBackdrop={false}
                            sx={{ fontWeight: 'bold' }}
                        />
                    </Grid>
                    <CmsPlacement
                        gridWidth={12}
                        subheadingTranslationKey="cms:help.new_to_bladebinge"
                        sx={{
                            minHeight: '380px',
                            backgroundPosition: 'top center',
                            minWidth: {
                                xs: 'auto',
                                sm: 'auto',
                                md: '340px',
                                lg: 'auto',
                                xl: 'auto'
                            }
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <StyledHelpLink
                                target="_blank"
                                href="https://intercom.help/bladebinge/en/articles/9010873-buyer-s-guide"
                            >
                                <ShoppingBagIcon sx={{ position: 'relative', top: '4px', mr: 1 }} />
                                {t('cms:help.buyers_guide')}
                            </StyledHelpLink>
                            <StyledHelpLink
                                target="_blank"
                                href="https://intercom.help/bladebinge/en/articles/9011002-seller-s-guide"
                            >
                                <SellIcon sx={{ position: 'relative', top: '4px', mr: 1 }} />
                                {t('cms:help.sellers_guide')}
                            </StyledHelpLink>
                            <StyledHelpLink target="_blank" href="https://intercom.help/bladebinge/en">
                                <HelpCenterIcon sx={{ position: 'relative', top: '4px', mr: 1 }} />
                                {t('cms:help.help_center')}
                            </StyledHelpLink>
                        </Box>
                    </CmsPlacement>
                </Grid>
            </Grid>
        </Grid>
    );
};
