import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { CmsTaglineProps } from '@bladebinge/types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';

const StyledAnchor = styled(Link)(({ theme }) => ({
    textDecoration: 'none'
}));

const TAGLINE_FONT_SIZES = {
    xs: '0.9rem',
    sm: '1rem',
    md: '1rem',
    lg: '1rem'
};

const TAGLINE_ICON_FONT_SIZES = {
    xs: '0.30rem',
    sm: '0.35rem',
    md: '0.45rem',
    lg: '0.65rem'
};

const TaglineHeading = ({
    headingTextColor,
    textColor,
    text
}: {
    readonly headingTextColor?: string;
    readonly textColor?: string;
    readonly text: string;
}) => (
    <Typography
        component="span"
        variant="h6"
        sx={{
            position: 'relative',
            top: '2px',
            display: 'inline-block',
            mr: 0.5,
            my: 0,
            ...(textColor ? { color: textColor } : {})
        }}
    >
        {text}
    </Typography>
);

const Tagline = ({
    headingTextColor,
    textColor,
    headingText,
    text
}: {
    readonly headingTextColor?: string;
    readonly textColor?: string;
    readonly headingText?: string;
    readonly text: string;
}) => (
    <Box
        sx={{
            fontWeight: 400,
            fontSize: TAGLINE_FONT_SIZES,
            ...(textColor ? { color: textColor } : {})
        }}
    >
        {headingText && <TaglineHeading textColor={headingTextColor} text={headingText} />}
        {text}
    </Box>
);

export const Taglines = ({ taglines }: { readonly taglines: CmsTaglineProps[] }) => {
    const { t } = useTranslation();

    if (taglines.length === 0) {
        return null;
    }

    return (
        <Grid item container direction="column" justifyContent="space-between">
            {taglines.map(
                ({
                    taglineTranslationHeadingKey,
                    taglineTranslationKey,
                    taglineLinkUrl,
                    children,
                    headingTextColor,
                    textColor
                }: CmsTaglineProps) => (
                    <Grid
                        item
                        key={`tagline_${taglineTranslationKey}`}
                        sx={{
                            py: {
                                xs: 0.5,
                                sm: 1.5,
                                md: 2
                            }
                        }}
                    >
                        <Grid container>
                            <Grid item xs={1}>
                                <CircleIcon
                                    sx={{
                                        position: 'relative',
                                        top: '5%',
                                        ml: {
                                            xs: 2,
                                            sm: 2,
                                            md: 0.75,
                                            lg: 0.75
                                        },
                                        fontSize: TAGLINE_ICON_FONT_SIZES,
                                        ...(textColor ? { color: textColor } : {})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={11}>
                                {taglineLinkUrl ? (
                                    <StyledAnchor href={taglineLinkUrl}>
                                        <>
                                            <Tagline
                                                textColor={textColor}
                                                headingTextColor={headingTextColor}
                                                headingText={
                                                    taglineTranslationHeadingKey ? t(taglineTranslationHeadingKey) : ''
                                                }
                                                text={t(taglineTranslationKey)}
                                            />
                                            {children}
                                        </>
                                    </StyledAnchor>
                                ) : (
                                    <>
                                        <Tagline
                                            textColor={textColor}
                                            headingTextColor={headingTextColor}
                                            headingText={
                                                taglineTranslationHeadingKey ? t(taglineTranslationHeadingKey) : ''
                                            }
                                            text={t(taglineTranslationKey)}
                                        />
                                        {children}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            )}
        </Grid>
    );
};
